<template>
  <div class="app">
    <div class="main">
      <!-- 贷款信息 -->
      <div class="info">
        <!-- 金额卡片 -->
        <div class="info-boxs">
          <div class="info-box" v-for="(item, index) in infodata" :key="index">
            <div class="box">
              <p
                style="
                  color: #ffffff;
                  font-size: 12px;
                  font-weight: 500;
                  opacity: 0.74;
                "
              >
                {{ item.title }}
              </p>
              <p style="color: #ffffff; font-size: 16px; font-weight: bold">
                {{ item.data }}
              </p>
            </div>
            <div
              style="
                width: 1px;
                height: 25px;
                background: #ffffff;
                opacity: 0.3;
              "
              v-if="index !== 4"
            ></div>
          </div>
        </div>
        <!-- 中间横线 -->
        <div class="info-line"></div>
        <!-- 查看账号 -->
        <div class="info-view">
          <van-button
            class="info-view-btn"
            round
            type="info"
            :icon="require('../../assets/img/ck.png')"
            icon-position="right"
            @click="ViewLoans"
            >查看还款账户</van-button
          >
        </div>
      </div>
      <!-- 贷款信息卡片 -->
      <div
        class="repayment"
        @click="card(item)"
        v-for="(item, index) in Orderdata"
        :key="index"
      >
        <!-- 标题 -->
        <div class="repayment-title">
          <div>
            <img src="../../assets/img/r.png" alt="" />
            <span class="repayment-span">{{ item.customerName }}</span>
          </div>
          <div>
            <!-- 还款中 -->
            <van-button
              v-if="item.isOverdue == 1 && item.orderIsClosed == 1"
              class="repayment-button"
              round
              type="info"
              size="small"
            >
              还款中
            </van-button>
            <!-- 已逾期 -->
            <van-button
              v-if="item.isOverdue == 2 && item.orderIsClosed == 1"
              class="Beoverdue-button"
              round
              type="info"
              size="small"
            >
              已逾期
            </van-button>
            <!-- 已结清 -->
            <van-button
              v-if="item.orderIsClosed == 4"
              class="settle-button"
              round
              type="info"
              size="small"
            >
              已结清
            </van-button>
          </div>
        </div>
        <!-- 内容 -->
        <div class="repayment-conter">
          <van-field
            style="width: 60%"
            v-model="item.ratifyMoney"
            label="贷款金额(元):"
            label-width="85px"
            readonly
          ></van-field>
          <van-field
            style="width: 40%"
            v-model="item.ratifyRepaymentPeriods"
            label="贷款期数:"
            label-width="60px"
            readonly
          >
          </van-field>
          <van-field
            style="width: 60%"
            v-model="item.repaymentType"
            label="还款方式:"
            label-width="85px"
            readonly
          >
          </van-field>
          <van-field
            style="width: 40%"
            v-model="item.payPeriods"
            label="已还期数:"
            label-width="60px"
            readonly
          >
          </van-field>
          <van-field
            style="width: 60%"
            v-model="item.principal"
            label="剩余本金(元):"
            label-width="85px"
            readonly
          >
          </van-field>
        </div>
      </div>
    </div>
    <!-- 查看账号弹出层 -->
    <van-popup
      v-model="show"
      closeable
      round
      close-icon="close"
      style="width: 90%"
    >
      <div class="popup-box">
        <div class="popup-title">还款账户</div>
        <div class="popup-field">
          <!-- 开户行 -->
          <van-field
            v-model="makeLoansBank.makeLoansBankOpening"
            clearable
            readonly
            autosize
            type="textarea"
            label="开户行:"
            label-width="50px"
          >
            <template #button>
              <van-button
                class="field-btn"
                size="small"
                type="primary"
                :icon="require('../../assets/img/fz.png')"
                @click="copy(makeLoansBank.makeLoansBankOpening)"
                >复制</van-button
              >
            </template>
          </van-field>
          <!-- 账户名 -->
          <van-field
            v-model="makeLoansBank.makeLoansBankName"
            clearable
            readonly
            autosize
            type="textarea"
            label="账户名:"
            label-width="50px"
          >
            <template #button>
              <van-button
                class="field-btn"
                size="small"
                type="primary"
                :icon="require('../../assets/img/fz.png')"
                @click="copy(makeLoansBank.makeLoansBankName)"
                >复制</van-button
              >
            </template>
          </van-field>
          <!-- 账号 -->
          <van-field
            v-model="makeLoansBank.makeLoansBankNumber"
            center
            clearable
            readonly
            label="账号:"
            label-width="50px"
          >
            <template #button>
              <van-button
                class="field-btn"
                size="small"
                type="primary"
                :icon="require('../../assets/img/fz.png')"
                @click="copy(makeLoansBank.makeLoansBankNumber)"
                >复制</van-button
              >
            </template>
          </van-field>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  getCustomersLoanInfo,
  getCustomerOrderBank,
} from "../../api/home/home";
export default {
  data() {
    return {
      // 订单详情
      infodata: [
        {
          title: "贷款总额(元)",
          data: "0.00",
        },
        {
          title: "剩余总余额(元)",
          data: "0.00",
        },
        {
          title: "申请笔数",
          data: "0.00",
        },
        {
          title: "逾期总违约金(元)",
          data: "0.00",
        },
        {
          title: "本期应还总额(元)",
          data: "0.00",
        },
      ],
      // 贷款详情
      Orderdata: {
        ratifyMoney: "0",
        ratifyRepaymentPeriods: "0",
        repaymentType: "等额本息",
        payPeriods: "0",
        principal: "0",
        isOverdue: 1,
        orderIsClosed: 1,
      },
      // 还款账号
      makeLoansBank: {},
      show: false,
    };
  },
  methods: {
    // 获取贷款信息
    getgetCustomersLoanInfo() {
      getCustomersLoanInfo().then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.infodata[0].data = res.data.data.ratifyMoney;
          this.infodata[1].data = res.data.data.money;
          this.infodata[2].data = res.data.data.orderCount;
          this.infodata[3].data = res.data.data.principal;
          this.infodata[4].data = res.data.data.toMoney;
          // 翻译还款方法
          this.Orderdata = res.data.data.customerOrderList;
          for (let i = 0; i < this.Orderdata.length; i++) {
            if (this.Orderdata[i].repaymentType == 1) {
              this.Orderdata[i].repaymentType = "先息后本";
            } else if (this.Orderdata[i].repaymentType == 2) {
              this.Orderdata[i].repaymentType = "分期贷";
            } else if (this.Orderdata[i].repaymentType == 3) {
              this.Orderdata[i].repaymentType = "气球贷";
            } else if (this.Orderdata[i].repaymentType == 4) {
              this.Orderdata[i].repaymentType = "等额本息";
            }
          }
        }
      });
    },
    // 获取 还款账号
    getgetCustomerOrderBank() {
      getCustomerOrderBank().then((res) => {
        if (res.data.code == 0) {
          // console.log(res.data.data);
          this.makeLoansBank = res.data.data;
        }
      });
    },
    // 查看还款账号
    ViewLoans() {
      this.show = true;
    },
    // 点击卡片
    card(item) {
      if (item.isOverdue == 1 && item.orderIsClosed == 1) {
        // 跳转到 还款中
        this.$router.push({
          path: "/repayment",
          query: {
            orderId: item.orderId,
            Orderdata: JSON.stringify(item),
          },
        });
      } else if (item.isOverdue == 2 && item.orderIsClosed == 1) {
        // 跳转到 逾期中
        this.$router.push({
          path: "/beoverdue",
          query: {
            orderId: item.orderId,
            Orderdata: JSON.stringify(item),
          },
        });
      } else if (item.orderIsClosed == 4) {
        // 跳转到 已结清
        this.$router.push({
          path: "/settle",
          query: {
            orderId: item.orderId,
            Orderdata: JSON.stringify(item),
          },
        });
      }
    },
    // 复制
    copy(data) {
      //创建一个input元素
      let input = document.createElement("input");
      //给input的内容复制
      input.value = data;
      // 在body里面插入这个元素
      document.body.appendChild(input);
      // 选中input里面内容
      input.select();
      //执行document里面的复制方法
      document.execCommand("Copy");
      // 复制之后移除这个元素
      document.body.removeChild(input);
      this.$toast("复制成功");
    },
  },
  created() {
    this.getgetCustomersLoanInfo();
    this.getgetCustomerOrderBank();
  },
};
</script>

<style lang="less" scoped>
.app {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
}
.main {
  width: 90%;
  margin: auto;
  padding-top: 20px;
}
.info {
  width: 100%;
  height: 200px;
  background: url("../../assets/img/bj@2x.png") no-repeat;
  background-size: 100%;
  border-radius: 10px;
  .info-boxs {
    width: 90%;
    margin: auto;
    height: 65%;
    padding-top: 5%;
    display: flex;
    flex-wrap: wrap;
    .info-box {
      width: 33%;
      height: 30%;
      // line-height: 30%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .info-line {
    width: 100%;
    height: 8px;
    background: #d3c4a3;
  }
  .info-view {
    height: 30% - 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    .info-view-btn {
      width: 150px;
      height: 35px;
      color: #a78a48;
      background: #f4f0e7;
      border: 1px solid #ffffff;
    }
    .info-view-img {
      margin-left: 10px;
      width: 13px;
      height: 9px;
    }
  }
}
.repayment {
  margin-top: 20px;
  width: 100%;
  height: 200px;
  background-color: #fff;
  border-radius: 6px;
  .repayment-title {
    padding: 15px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    .repayment-span {
      color: black;
      font-weight: bold;
      margin-left: 10px;
    }
    .repayment-button {
      width: 80px;
      height: 30px;
      color: #48a79d;
      background: rgba(72, 167, 157, 0.2);
      border: rgba(72, 167, 157, 0.2);
    }
    .Beoverdue-button {
      width: 80px;
      height: 30px;
      color: #a75248;
      background: rgba(255, 150, 99, 0.2);
      border: rgba(255, 150, 99, 0.2);
    }
    .settle-button {
      width: 80px;
      height: 30px;
      color: #a78a48;
      background: rgba(167, 138, 72, 0.2);
      border: rgba(167, 138, 72, 0.2);
    }
  }
}
.repayment-conter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.popup-box {
  height: 230px;
  .popup-title {
    height: 45px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup-field {
    border-top: 1px solid #f0f0f0;
    .field-btn {
      width: 70px;
      height: 24px;
      color: #a78a48;
      background-color: rgba(167, 138, 72, 0.3);
      border: rgba(167, 138, 72, 0.3);
      border-radius: 12px;
    }
  }
}
</style>
